import {
  type DtoPromptTemplate,
  EnumsPromptTemplateType,
} from '@lp-lib/api-service-client/public';

import { getFeatureQueryParam } from '../../hooks/useFeatureQueryParam';

export class PromptTemplateUtils {
  static OwnedBySystem(template: Nullable<DtoPromptTemplate>): boolean {
    return template?.ownerType === 'System';
  }

  static Editable(
    template: Nullable<DtoPromptTemplate>,
    superAdmin = getFeatureQueryParam('super-admin')
  ): boolean {
    return superAdmin || !this.OwnedBySystem(template);
  }

  static HintForType(type: EnumsPromptTemplateType) {
    switch (type) {
      case EnumsPromptTemplateType.PromptTemplateTypeSimple:
        return 'For simple chat conversation, if you are not sure, use this one.';
      case EnumsPromptTemplateType.PromptTemplateTypeAssistant:
        return 'Equivalent to the OpenAI Assistant, it has advanced support such as file search.';
      default:
        return 'Unknown type';
    }
  }
}
